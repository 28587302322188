* {
  margin: initial;
  padding: initial;
  box-sizing: border-box;
  border: initial;
  outline: initial;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  text-decoration: inherit;
  line-height: inherit;
  color: inherit;
  list-style: none;
  background: initial;
  height: auto;
  width: auto;
  resize: none;
}
body {
  background: #fff;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;
}
.underConstruction {
  color: #fff;
  font-size: 40px;
  background-color: #a2c037;
  padding: 16px;
}
@media (max-width: 1280px) {
  .underConstruction {
    font-size: 16px;
  }
}
.header {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: url("46c2a5ccde187d38102dbf503dc29cd7.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: height 0.2s, background 0.2s;
}
@media (min-width: 1280px) {
  .header {
    padding-left: 48px;
    padding-right: 48px;
  }
}
.header > :not(:first-child) {
  margin-left: 24px;
}
.header > :not(:last-child) {
  margin-right: 24px;
}
@media (max-width: 1280px) {
  .header {
    height: 64px;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .header {
    height: 160px;
    align-items: flex-end;
  }
}
.header_narrow {
  height: 64px;
  align-items: stretch;
}
.header_narrow .header__nav {
  align-items: stretch;
}
.header_narrow .header__item {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 0;
}
.header_narrow .header__logo_full {
  display: none;
}
.header_narrow .header__logo_icon {
  display: flex;
}
.header_transparent:not(.header_solid) {
  background: transparent;
}
.header__home {
  display: flex;
  align-items: center;
}
.header__logo_full {
  height: 64px;
  margin-bottom: 40px;
}
@media (max-width: 1280px) {
  .header__logo_full {
    display: none;
  }
}
.header__logo_icon {
  height: 48px;
}
@media (min-width: 1280px) {
  .header__logo_icon {
    display: none;
  }
}
.header__nav {
  display: flex;
}
@media (min-width: 1280px) {
  .header__nav > :not(:first-child) {
    margin-left: 24px;
  }
  .header__nav > :not(:last-child) {
    margin-right: 24px;
  }
}
@media (max-width: 1280px) {
  .header__nav {
    top: 16px;
    right: 16px;
    position: absolute;
    flex-direction: column;
    padding: 8px 0;
  }
}
@media (max-width: 1280px) {
  .header__nav_closed .header__item {
    pointer-events: none;
    opacity: 0;
  }
}
.header__item {
  position: relative;
}
@media (max-width: 1280px) {
  .header__item {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .header__item {
    font-size: 20px;
    padding-bottom: 40px;
  }
}
.header__item_active {
  font-weight: bold;
}
@media (min-width: 1280px) {
  .header__item_active {
    overflow: hidden;
  }
  .header__item_active::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -12px;
    display: block;
    background: #fff;
    width: 24px;
    height: 24px;
    transform-origin: center;
    transform: rotate(45deg);
  }
}
.header__link {
  -webkit-tap-highlight-color: transparent;
  display: block;
  transition: background 0.2s;
}
@media (max-width: 1280px) {
  .header__link {
    padding: 8px 16px;
  }
}
@media (max-width: 1280px) {
  .header__link:hover {
    background: rgba(255,255,255,0.1);
  }
}
.main {
  padding-left: 16px;
  padding-right: 16px;
  align-self: center;
  margin-bottom: 64px;
  width: 100%;
  max-width: 1096px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
@media (min-width: 1280px) {
  .main {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1280px) {
  .main {
    padding-top: 96px;
  }
}
@media (max-width: 1280px) {
  .main {
    padding-top: 16px;
  }
}
@media (min-width: 1280px) {
  .main:not(.main__home) {
    margin-top: 160px;
  }
}
@media (max-width: 1280px) {
  .main:not(.main__home) {
    margin-top: 64px;
  }
}
.footer {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  align-items: center;
  background: #57585a;
  color: #fff;
  height: 40px;
}
@media (min-width: 1280px) {
  .footer {
    padding-left: 48px;
    padding-right: 48px;
  }
}
.footer__link {
  display: flex;
  height: 20px;
  margin-left: 16px;
}
.footer__link_facebook {
  margin-left: 24px;
}
.footer__right {
  display: flex;
}
.footer__icon {
  float: left;
  width: 20px;
  height: 20px;
}
.slider {
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}
.slider::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0,0,0,0.5), transparent, transparent);
}
.slider__slides {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: stretch;
}
.slider__slide {
  position: relative;
  width: 100vw;
  height: 100vh;
  flex-shrink: 0;
}
.slider__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slider__caption {
  position: absolute;
  bottom: 20%;
  left: 0;
  display: flex;
  align-items: stretch;
}
.slider__text {
  padding-left: 16px;
  padding-right: 16px;
  background: #a2c037;
  color: #fff;
  font-size: 32px;
  line-height: 1.25em;
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (min-width: 1280px) {
  .slider__text {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (max-width: 1280px) {
  .slider__text {
    font-size: 16px;
  }
}
.slider__text em {
  font-weight: bold;
}
@media (min-width: 768px) {
  .slider__text em {
    font-size: 1.6em;
  }
}
@media (max-width: 768px) {
  .slider__text br {
    display: none;
  }
}
.slider__triangle {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100px;
  flex-shrink: 0;
}
.slider__triangle::before {
  content: '';
  display: block;
  height: 400px;
  width: 300px;
  position: absolute;
  top: 0;
  left: -300px;
  background: #a2c037;
  transform-origin: top right;
  transform: rotate(-25deg);
}
.slider__arrow {
  position: absolute;
  z-index: 1;
  width: 32px;
  margin-left: -16px;
  bottom: 16px;
  left: 50%;
  transition: transform 0.2s;
}
.slider__arrow:hover {
  transform: translateY(4px);
}
.slider__arrow img {
  width: 100%;
}
.tile-grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  margin-bottom: 64px;
}
@media (max-width: 768px) {
  .tile-grid {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .tile-grid .tile {
    margin-bottom: 8px;
  }
}
.tile {
  background: #a2c037;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding: 32px 24px 24px 24px;
}
@media (max-width: 768px) {
  .tile {
    flex-direction: row;
    justify-content: flex-start;
    padding: 16px;
  }
}
.tile__icon {
  transition: transform 0.2s;
  transform-origin: center;
}
@media (max-width: 768px) {
  .tile__icon {
    height: 64px;
    margin-right: 16px;
  }
}
@media (min-width: 768px) {
  .tile__icon {
    height: 96px;
    margin-bottom: 16px;
  }
}
.tile__icon_shadow {
  height: 96px;
}
.tile:hover .tile__icon {
  transform: scale(1.1);
}
.tile__text {
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
}
@media (max-width: 1280px) {
  .tile__text {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .tile__text {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .tile__text {
    text-align: center;
  }
}
.tile__text > :first-child::after {
  content: ".";
}
.special-tile {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .special-tile {
    display: none;
  }
}
.special-tile__image {
  height: 160px;
}
.service {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
}
.service__header {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  padding: 16px 0;
  margin-bottom: 32px;
}
.service__header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 4px solid #a2c037;
}
@media (max-width: 1280px) {
  .service__header::after {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .service__header::after {
    width: 9999px;
  }
}
.service__top {
  position: absolute;
}
@media (min-width: 1280px) {
  .service__top {
    top: -64px;
  }
}
@media (max-width: 1280px) {
  .service__top {
    top: -64px;
  }
}
.service__heading {
  margin-bottom: 8px;
  color: #a2c037;
  line-height: 1em;
}
.service__heading::after {
  content: ".";
}
@media (max-width: 1280px) {
  .service__heading {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .service__heading {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .service__icon {
    height: 64px;
  }
}
@media (min-width: 768px) {
  .service__icon {
    height: 80px;
  }
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 16px;
}
.col > h1 {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 24px;
  color: #a2c037;
  line-height: 1.2em;
}
.col > h1::after {
  content: ".";
}
.col > h2 {
  font-weight: bold;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 8px;
}
.col > h2::after {
  content: ".";
}
.col > h3 {
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
}
.col > p,
.col > ul,
.col > ol,
.col > dl {
  margin: 8px 0;
}
.col > p > li,
.col > ul > li,
.col > ol > li,
.col > dl > li {
  margin: 4px 0;
}
.col > p > li:not(.link),
.col > ul > li:not(.link),
.col > ol > li:not(.link),
.col > dl > li:not(.link) {
  padding-left: 6px;
}
.col > p > li:not(.link)::before,
.col > ul > li:not(.link)::before,
.col > ol > li:not(.link)::before,
.col > dl > li:not(.link)::before {
  color: #a2c037;
  content: "_";
  display: inline-block;
  margin-left: -6px;
  width: 6px;
}
.col > p strong,
.col > ul > li strong,
.col > ol > li strong {
  font-weight: bold;
}
.col > p strong:first-child::after,
.col > ul > li strong:first-child::after,
.col > ol > li strong:first-child::after {
  content: ".";
}
.col > p a,
.col > ul > li a,
.col > ol > li a {
  color: #a2c037;
}
.col > p a:hover,
.col > ul > li a:hover,
.col > ol > li a:hover {
  text-decoration: underline;
}
.col > p em:first-child,
.col > ul > li em:first-child,
.col > ol > li em:first-child {
  font-weight: bold;
}
.col > dt {
  font-weight: bold;
}
.col > dt::after {
  content: ".";
}
.col > dt:not(:first-child) {
  margin-top: 16px;
}
.col > hr {
  border-bottom: 1px solid #57585a;
  margin: 64px 0;
}
.col > hr.invisible {
  border-color: transparent;
}
.col > img {
  width: 100%;
  margin: 64px 0;
}
.col > section {
  margin: 32px 0 16px 0;
}
.col > :first-child {
  margin-top: 0;
}
.col > :last-child {
  margin-bottom: 0;
}
.row {
  display: flex;
}
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}
.row > .col:first-child > :first-child {
  margin-top: 0;
}
@media (min-width: 768px) {
  .row > .col:first-child > :last-child {
    margin-bottom: 0;
  }
}
.row > .col:last-child > :last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .row > .col:last-child > :first-child {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .row > .col:not(:first-child) {
    margin-left: 48px;
  }
}
.partners {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
@media (max-width: 768px) {
  .partners {
    align-items: center;
    flex-direction: column;
  }
}
.partners__item {
  max-height: 48px;
}
@media (max-width: 768px) {
  .partners__item {
    margin: 16px;
  }
}
.partners__item:not(:first-child) {
  margin-left: 32px;
}
.badge-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.badge-text__badge {
  width: 160px;
  height: 160px;
  margin: 0;
  margin-right: 64px;
}
@media (max-width: 768px) {
  .badge-text__badge {
    width: 80px;
    height: 80px;
    margin-right: 32px;
  }
}
.badge-text__text {
  flex: 1;
}
.form {
  display: flex;
  flex-direction: column;
}
.form__element {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 20px;
}
.form__element_required .form__label::after {
  content: "*";
  color: #a2c037;
}
.form__label {
  font-size: 13px;
  line-height: 16px;
  margin-top: 4px;
}
.form input,
.form select {
  padding: 8px;
  height: 32px;
  font-size: 13px;
  line-height: 16px;
  background: #e7e9e8;
}
.button {
  background: #a2c037;
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 24px;
}
.button b {
  font-weight: bold;
}
.inform-grid {
  margin-top: 8px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(5, 1fr);
  grid-suto-rows: auto;
}
@media (max-width: 768px) {
  .inform-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .inform-grid .tile {
    margin-bottom: 8px;
  }
}
.inform__cover {
  width: 100%;
}
.inform__title {
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
}
.timeline {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.timeline__svg {
  margin: 0;
  padding: 0;
}
.timeline__box {
  padding: 8px;
  background: #a2c037;
  color: #fff;
}
.timeline__year {
  font-weight: bold;
}
.timeline__year::after {
  content: ". ";
}
@media (max-width: 768px) {
  .timeline__svg {
    display: none;
  }
  .timeline__item:not(:last-child) {
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) {
  .timeline__container {
    position: relative;
  }
  .timeline__item {
    cursor: pointer;
    position: absolute;
  }
  .timeline__item::before {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
    border-radius: 20px;
    border: 6px solid #a2c037;
    width: 8px;
    height: 8px;
    left: -10px;
    top: -10px;
    transition: transform 0.2s, background 0.2s;
  }
  .timeline__item:hover::before {
    transform: scale(1.5);
    background: #a2c037;
  }
  .timeline__item:nth-child(even) .timeline__box {
    right: 32px;
    top: -16px;
  }
  .timeline__item:nth-child(odd) .timeline__box {
    left: 32px;
    top: -16px;
  }
  .timeline__path {
    fill: none;
    stroke: #57585a;
    stroke-width: 2;
  }
  .timeline__axis {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .timeline__box {
    position: absolute;
  }
  .timeline__text {
    display: none;
    position: absolute;
  }
  .timeline__item_active .timeline__box {
    width: 310px;
  }
  .timeline__item_active .timeline__text {
    position: static;
    display: inline;
  }
}
@media all and (-ms-high-contrast: none) {
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .tile-grid {
    float: left;
    width: 100%;
    clear: both;
  }
  .tile-grid :nth-child(3n) {
    margin-right: 0;
  }
  .tile,
  .special-tile {
    float: left;
    width: 30%;
    margin-right: 5%;
    margin-bottom: 32px;
  }
  .header__logo.header__logo_full {
    width: 198.33333333333334px;
    height: 50.333333333333336px;
    float: left;
  }
  .service {
    float: left;
    width: 100%;
  }
  .service__header {
    display: block;
  }
  .service__heading {
    float: left;
    margin-top: 64px;
  }
  .service__icon {
    float: right;
  }
  .main,
  .col,
  .col > * {
    display: block;
    float: left;
    width: 100%;
    flex: none;
  }
  .main {
    margin-bottom: 88px;
  }
  .row > .col {
    width: 45%;
  }
  .badge-text {
    display: block;
    float: left;
    width: 100%;
    flex: none;
  }
  .badge-text__badge {
    float: left;
    width: 30%;
    display: block;
  }
  .badge-text__badge img {
    float: left;
    width: 160px;
  }
  .badge-text__text {
    float: left;
    width: 60%;
  }
  .partners__item {
    margin-right: 64px;
  }
  .timeline {
    position: static;
    float: left;
    width: 100%;
  }
  .timeline__svg {
    display: none;
  }
  .timeline__item,
  .timeline__box,
  .timeline__axis {
    cursor: normal;
    position: static;
    display: block;
    float: left;
    width: 100%;
  }
  .timeline__item {
    margin-bottom: 16px;
  }
  .timeline__item_active .timeline__box {
    width: 100%;
  }
  .timeline__text {
    position: static;
    display: inline;
  }
  .timeline__item::before {
    display: none;
  }
  .inform-grid {
    float: left;
    width: 100%;
    margin: -8px;
  }
  .inform {
    float: left;
    width: 20%;
    padding: 16px;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 768px) {
  .inform {
    width: 50%;
  }
}
